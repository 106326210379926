import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { CustomInput } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import { context } from '../../store/PorterDelivery/DepositPackage';
import Layout from './Layout';
import { sendAmplitudeData } from '../../services/amplitude';
import {
  amplitude,
  porterDeliveryStep,
  porterDeliveryStepNames,
} from '../../constants';
import FooterDeliveryToCondominium from '../FooterDeliveryToCondominium';
import * as S from './styles';

const StepUnity = () => {
  const {
    handleNextStep,
    handleBackStep,
    groupUnities,
    unity,
    setUnity,
    groupUnity,
    deliveryToCondominium,
    condominiumInfo,
    setSingleUnityGroup,
  } = useContext(context);

  const [t] = useTranslation('porter-delivery');
  const [search, setSearch] = useState('');
  const [unityList, setUnityList] = useState([]);

  const hasOnlyOneGroupUnities = groupUnities.length <= 1;

  const unitiesListByGroupUnities = useMemo(() => {
    const fallbackGroupUnitiesList =
      condominiumInfo.getCondominiumInfo.unityGroup;
    if (!groupUnity && fallbackGroupUnitiesList.length >= 1)
      return fallbackGroupUnitiesList[0]?.unity;
    return groupUnity?.unity;
  }, []);

  const handleChangeUnity = unityData => {
    setUnity(unityData);
    if (hasOnlyOneGroupUnities) setSingleUnityGroup();
    handleNextStep();
  };

  const handleSearchUnities = (_, value) => {
    setSearch(value.trim());

    if (!value.trim()) return setUnityList(unitiesListByGroupUnities);

    const filteredList =
      unitiesListByGroupUnities.filter(u =>
        String(u.name.toLowerCase()).includes(value.trim().toLowerCase()),
      ) || [];
    return setUnityList(filteredList);
  };

  useEffect(
    () =>
      sendAmplitudeData(amplitude.porterDelivery.page_view, {
        page: porterDeliveryStep[porterDeliveryStepNames.unity],
      }),
    [],
  );

  useEffect(() => {
    if (unitiesListByGroupUnities.length)
      setUnityList(unitiesListByGroupUnities);
  }, []);

  return (
    <Layout
      onStepBack={handleBackStep}
      footer={
        hasOnlyOneGroupUnities && (
          <FooterDeliveryToCondominium
            onClick={deliveryToCondominium}
            title={t('unity.to-condominium')}
            buttonText={t('unity.selection-button.to-condominium')}
          />
        )
      }
    >
      <S.WrapperStepUnity>
        <S.Label>{t('unity.step-title')}</S.Label>

        <CustomInput
          type="text"
          name="unity.search"
          value={search}
          onChange={handleSearchUnities}
          placeholder={t('unity.search')}
          autoComplete="off"
          primaryColor="invitePorterSecondary"
          variant="rounded"
          size="large"
          icon={<MdSearch />}
          clearField
        />

        {unityList && !!unityList.length ? (
          <S.UnityOptions>
            {unityList.map(unityData => (
              <Fragment key={unityData.id}>
                <S.UnityButton
                  active={unityData.id === unity?.id}
                  onClick={() => handleChangeUnity(unityData)}
                >
                  {unityData.name}
                </S.UnityButton>
              </Fragment>
            ))}
          </S.UnityOptions>
        ) : (
          <S.EmptyStateUnities>{t('unity.empty-state')}</S.EmptyStateUnities>
        )}
      </S.WrapperStepUnity>
    </Layout>
  );
};

export default StepUnity;
