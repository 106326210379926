import { graphql as sharedSchemaGraphql } from '@kiper/shared-schema';
import condominium from './condominium';
import * as auth from './auth';
import * as accessRf from './access_rf';
import * as accessTag from './access_tag';
import * as adamSender from './adam_sender';
import * as attendance from './attendance';
import * as dweller from './dweller';
import * as delivery from './delivery';
import * as event from './event';
import * as allowedAccess from './person-context-allowed-access';
import * as camera from './camera';
import * as placeTypeLabel from './place_type_label';
import * as person from './person';
import * as invite from './invite';
import * as applicationProfile from './application_profile';
import * as systemUser from './system_user';
import * as templateParams from './template_params';
import * as report from './report';
import * as attendanceDefaultMessage from './attendance_default_message';
import * as systemSettings from './system_settings';
import * as permission from './permission';
import * as data from './data';
import * as triage from './triage';
import * as device from './device';
import * as feedback from './feedback';
import * as fingerprint from './fingerprint';
import * as accessFacial from './access_facial';
import * as eventGenerator from './event_generator';
import * as guidedAttendance from './guided_attendance';
import * as preRegister from './pre_register';
import * as placeSelect from './place_select';
import * as condoMap from './condo_map';
import * as transientPersonRuleSetting from './transient_person_rule_setting';
import * as guidedAttendanceSettings from './guided_attendance_settings';
import * as eventSchedulerSettings from './event_scheduler_settings';

const { eventSettings, events } = sharedSchemaGraphql;

export default condominium;
export {
  accessRf,
  eventSettings,
  events,
  condominium,
  auth,
  accessTag,
  adamSender,
  attendance,
  dweller,
  delivery,
  event,
  allowedAccess,
  camera,
  placeTypeLabel,
  person,
  invite,
  applicationProfile,
  systemUser,
  templateParams,
  report,
  sharedSchemaGraphql,
  attendanceDefaultMessage,
  systemSettings,
  permission,
  data,
  fingerprint,
  triage,
  device,
  feedback,
  accessFacial,
  eventGenerator,
  guidedAttendance,
  preRegister,
  condoMap,
  transientPersonRuleSetting,
  placeSelect,
  guidedAttendanceSettings,
  eventSchedulerSettings,
};
