import gql from 'graphql-tag';

export const placeSelect = gql`
  query placeSelect($filters: PlaceSelectQueryInput!) {
    placeSelect(filters: $filters) {
      label
      value
      treeNodeId
      parentTreeNodeId
      type
    }
  }
`;

export const userGuUnPlaceSelect = gql`
  query placeSelect($filters: PlaceSelectQueryInput!) {
    placeSelect(filters: $filters) {
      label
      treeNodeId
      parentTreeNodeId
      type
      defaultAccessProfile
      entityId
    }
  }
`;
