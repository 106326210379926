import gql from 'graphql-tag';

export const get = gql`
  query get($guestHash: String) {
    getInviteGuest(guestHash: $guestHash) {
      id
      hashId
      invite {
        id
        name
        start
        end
      }
      condominium {
        id
        name
        email
        phone
        treeNodeId
      }
      unityGroup {
        id
        name
      }
      unity {
        id
        name
      }
      address {
        id
        addressTypeId
        cityId
        streetName
        addressNumber
        additionalInfo
        isPrincipal
      }
      isPorterPartner
      partnerPersonContextId
      condominiumPersonContextId
      hasFaceId
      hasFacial
      hasQrCode
      qrCode
      allowFaceEdit
    }
  }
`;
