import { useMemo, useState } from 'react';
import { usePermission } from '@kiper/hooks';
import Context from './index';
import FacialInvitationPorterSummitModal from '../FacialInvitationPorterSummitModal';
import { sendAmplitudeData } from '../../../../services/amplitude';

const stepName = Object.freeze({
  0: 'terms of use',
  1: 'face capture tips',
  2: 'face capture',
  3: 'face capture confirm',
  4: 'face loading',
  5: 'feedback success',
});

const FacialInvitationPorterSummitProvider = props => {
  const permissionsCamera = usePermission('camera');

  const [photoUrl, setPhotoUrl] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userMediaError, setUserMediaError] = useState(false);
  const [numberOfAttemps, setNumberOfAttemps] = useState(1);

  const handleResetStep = () => {
    setStep(0);
  };

  const handleChangeStep = value => {
    setStep(x => x + value);
  };

  const sendAmplitude = (key, params) => {
    sendAmplitudeData(key, {
      step,
      stepName: stepName[step],
      ...params,
    });
  };

  const value = useMemo(
    () => ({
      photoUrl,
      setPhotoUrl,
      step,
      handleResetStep,
      handleChangeStep,
      loading,
      setLoading,
      sendAmplitude,
      permissionsCamera,
      userMediaError,
      setUserMediaError,
      numberOfAttemps,
      setNumberOfAttemps,
    }),
    [
      photoUrl,
      step,
      userMediaError,
      numberOfAttemps,
      loading,
      permissionsCamera,
    ],
  );

  return (
    <Context.Provider value={value}>
      <FacialInvitationPorterSummitModal {...props} />
    </Context.Provider>
  );
};

export default FacialInvitationPorterSummitProvider;
