import gql from 'graphql-tag';

export const deviceModelFragment = gql`
  fragment deviceModelParts on DeviceModel {
    id
    deviceTypeId
    name
    capabilities
    creationDate
  }
`;

export const deviceTypeFragment = gql`
  fragment deviceTypeParts on DeviceType {
    id
    name
    fieldName
    isCpu
    isController
    creationDate
  }
`;

export const deviceFragment = gql`
  fragment deviceParts on Device {
    id
    placeId
    parentDeviceId
    deviceTypeId
    deviceType {
      ...deviceTypeParts
    }
    serialNumber
    ip
    protocolVersion
    firmwareVersion
    name
    isOnline
    serializedParams
    creationDate
    lastUpdate
    updateStatus
    chipsetType
    syncStatus
    immutableData
    deviceModel {
      ...deviceModelParts
    }
  }
  ${deviceModelFragment}
  ${deviceTypeFragment}
`;

export const configuredDeviceFragment = gql`
  fragment configuredDeviceParts on ConfiguredDevice {
    id
    sipAccountId
    typeSip
    status
    device {
      ...deviceParts
    }
  }
  ${deviceFragment}
`;

export const configuredAtaFragment = gql`
  fragment configuredAtaParts on ConfiguredAta {
    id
    sipAccountId
    sipPassword
    typeSip
    status
  }
`;
