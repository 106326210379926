import gql from 'graphql-tag';

const whoAmIObject = `
    name
    email
    personContextId
    personId
    profileId
    profileName
    topNodeId
    topContextId
    personContextParams {
      id
      name
      value {
        user
        password
        monitor
        agentId
      }
      lastUpdate
      creationDate
    }
    partner {
      id
      name
      hasKiperVoice
      hasCoPartner
      treeNodeId
      personContextId
      personContextParams {
        id
        name
        value {
          domain
          serverUrl
          enabled
          voiceToken
        }
        lastUpdate
        creationDate
      }
    }
    partnerGroup {
      id
      name
      treeNodeId
      personContextId
      personContextParams {
        id
        name
        value {
          domain
          serverUrl
          enabled
        }
        lastUpdate
        creationDate
      }
    }
    condominium {
      id
      name
      treeNodeId
      personContextId
      personContextParams {
        id
        name
        value {
          domain
          serverUrl
          enabled
        }
        lastUpdate
        creationDate
      }
    }
`;

export const whoAmIQuery = gql`
  query whoAmI {
    whoAmI {
     ${whoAmIObject}
    }
  }
`;

export const signOutMutation = gql`
  mutation signOut {
    signOut
  }
`;

export const currentLoggedContext = gql`
  {
    currentLoggedContext @client {
     ${whoAmIObject}
    }
  }
`;

export const setCurrentLoggedContext = gql`
  mutation setCurrentLoggedContext {
    setCurrentLoggedContext {
      ${whoAmIObject}
    }
  }
`;

export const switchContextMutation = gql`
  mutation switchContext($currentLoggedContext: LoggedContext!) {
    setCurrentLoggedContext(currentLoggedContext: $currentLoggedContext) @client
  }
`;

export const refreshTokenMutation = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      idToken
      accessToken
      refreshToken
    }
  }
`;

export const signS3Cookies = gql`
  query signS3Cookies {
    signS3Cookies {
      CloudFrontPolicy
      CloudFrontSignature
      CloudFrontKeyPairId
    }
  }
`;
