import { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { context } from '../../store/PorterDelivery/DepositPackage';
import { sendAmplitudeData } from '../../services/amplitude';
import {
  amplitude,
  porterDeliveryStep,
  porterDeliveryStepNames,
} from '../../constants';
import Layout from './Layout';
import GroupUnityButton from '../GroupUnityButton';
import FooterDeliveryToCondominium from '../FooterDeliveryToCondominium';
import { GroupUnityButtonIcon } from '../SvgIcons';
import * as S from './styles';

const StepGroupUnity = () => {
  const {
    handleNextStep,
    handleBackStep,
    deliveryToCondominium,
    setGroupUnity,
    groupUnities,
  } = useContext(context);

  const [t] = useTranslation('porter-delivery');

  useEffect(
    () =>
      sendAmplitudeData(amplitude.porterDelivery.page_view, {
        page: porterDeliveryStep[porterDeliveryStepNames.groupUnity],
      }),
    [],
  );

  const handleChangeGroupUnity = groupUnityData => {
    setGroupUnity(groupUnityData);
    handleNextStep();
  };

  return (
    <Layout
      onStepBack={handleBackStep}
      footer={
        <FooterDeliveryToCondominium
          onClick={deliveryToCondominium}
          title={t('group-unities.to-condominium')}
          buttonText={t('group-unities.selection-button.to-condominium')}
        />
      }
    >
      <S.WrapperStepGroupUnity>
        <S.Label>{t('group-unities.step-title')}</S.Label>
        <S.GroupUnityOptions>
          {groupUnities &&
            !!groupUnities.length &&
            groupUnities.map(groupUnityData => (
              <Fragment key={groupUnityData.id}>
                <GroupUnityButton
                  icon={<GroupUnityButtonIcon />}
                  onClick={() => handleChangeGroupUnity(groupUnityData)}
                >
                  {groupUnityData.name}
                </GroupUnityButton>
              </Fragment>
            ))}
        </S.GroupUnityOptions>
      </S.WrapperStepGroupUnity>
    </Layout>
  );
};

export default StepGroupUnity;
