import gql from 'graphql-tag';

export const syncFacialDevice = gql`
  mutation syncFacialDevice($deviceId: Int!) {
    syncFacialDevice(deviceId: $deviceId)
  }
`;

export const forceDeviceConnection = gql`
  mutation forceDeviceConnection($deviceId: Int!) {
    forceDeviceConnection(deviceId: $deviceId)
  }
`;

export const checkDeviceExist = gql`
  mutation checkDeviceExist($deviceId: String!, $type: String!) {
    checkDeviceExist(deviceId: $deviceId, type: $type)
  }
`;
