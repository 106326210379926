import gql from 'graphql-tag';
import {
  configuredAtaFragment,
  configuredDeviceFragment,
  deviceFragment,
} from './fragments';

export const configuredDevices = gql`
  query configuredDevices($condominiumId: Int!) {
    configuredDevices(condominiumId: $condominiumId) {
      ...configuredDeviceParts
    }
  }
  ${configuredDeviceFragment}
`;

export const configuredAtas = gql`
  query configuredAtas($condominiumId: Int!) {
    configuredAtas(condominiumId: $condominiumId) {
      ...configuredAtaParts
    }
  }
  ${configuredAtaFragment}
`;

export const availableDevices = gql`
  query availableDevices($condominiumId: Int!) {
    availableDevices(condominiumId: $condominiumId) {
      ...deviceParts
    }
  }
  ${deviceFragment}
`;
