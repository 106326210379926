export const inviteStatus = {
  expired: 0,
  valid: 1,
  invalid: 2,
};

export const amplitude = Object.freeze({
  porterDelivery: {
    start_deposit: 'condobox start deposit',
    page_view: 'condobox page view',
    step_back: 'condobox step back',
    next_step: 'condobox next step',
    to_step: 'condobox to step',
    open: 'condobox open box',
    open_failure: 'condobox open box failure',
    deposit_success: 'condobox deposit success',
    deposit_failure: 'condobox deposit failure',
    new_deposit: 'condobox new deposit',
    go_to_receipt: 'condobox go to receipt',
    reset_step: 'condobox reset step',
    already_have_saved_phone: 'already have saved phone',
  },
});

export const porterDeliveryStep = Object.freeze({
  0: 'welcome',
  1: 'phone number',
  2: 'group unity',
  3: 'unity',
  4: 'open drawer',
  5: 'confirm deposit',
  6: 'feedback success',
});

export const porterDeliveryStepNames = Object.freeze({
  welcome: 0,
  phoneNumber: 1,
  groupUnity: 2,
  unity: 3,
  openDrawer: 4,
  confirmDeposit: 5,
  feedbackSuccess: 6,
});

export default inviteStatus;
