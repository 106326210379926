import { useContext } from 'react';
import propTypes from 'prop-types';
import {
  StepTermsOfUse,
  StepFaceCapture,
  StepFaceCaptureConfirm,
  StepFaceCaptureTips,
  StepLoading,
  StepFeedbackSuccess,
} from './components/steps';
import context from './context';
import BlockedCamAlert from './components/BlockedCamAlert';
import ErrorCamAlert from './components/ErrorCamAlert';
import * as S from './styles';

const FacialInvitationPorterSummitModal = props => {
  const { onToggle } = props;
  const { step, permissionsCamera, sendAmplitude, userMediaError } = useContext(
    context,
  );
  const { PermissionStateEnum, permissionState } = permissionsCamera;

  const steps = [
    <StepTermsOfUse key={step} {...props} />,
    <StepFaceCaptureTips key={step} {...props} />,
    <StepFaceCapture key={step} {...props} />,
    <StepFaceCaptureConfirm key={step} {...props} />,
    <StepLoading key={step} {...props} />,
    <StepFeedbackSuccess key={step} {...props} />,
  ];

  const handleToggle = () => {
    sendAmplitude('invite stop register facial');
    onToggle();
  };

  const camBlocked = permissionState === PermissionStateEnum.BLOCKED;

  if (camBlocked) {
    return <BlockedCamAlert onToggle={onToggle} />;
  }

  if (userMediaError) {
    return <ErrorCamAlert onToggle={onToggle} />;
  }

  return (
    <S.Modal
      open={!(camBlocked || userMediaError)}
      toggle={handleToggle}
      size="sm"
      centered
    >
      <S.Container>{steps[step]}</S.Container>
    </S.Modal>
  );
};

export default FacialInvitationPorterSummitModal;

FacialInvitationPorterSummitModal.propTypes = {
  onToggle: propTypes.func.isRequired,
};
