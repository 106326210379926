import 'bootstrap/dist/css/bootstrap.min.css';
import { createGlobalStyle } from 'styled-components';
import bootstrap from './bootstrap';

export default createGlobalStyle`
  body {
    font-size: 14px;
    @media screen {
      background-color: ${props => props.theme.colorContainer};
    }
    font-family: Nunito, 'Nunito Sans', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
  }

  #root {
    width: 100%;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  input,
  select,
  button,
  input:focus,
  select:focus,
  button:focus {
    outline: none;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }

  li {
    list-style: none;
    margin: none;
  }

  ul {
    margin: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: #d3d3d4;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.38);
  }

  ${bootstrap}
`;
