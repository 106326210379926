import { useContext, useEffect } from 'react';
import { Divider, Spinner } from '@kiper/ui';
import { useMutation } from 'react-apollo';
import { mutations } from '@kiper/account-graphql/package-delivery';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { MdOutlineArrowForward } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { context } from '../../store/PorterDelivery/DepositPackage';
import Layout from './Layout';
import fittingPiece from '../../assets/images/fitting-piece.svg';
import prohibitedFragileObject from '../../assets/images/prohibited-fragile-object.svg';
import prohibitedFood from '../../assets/images/prohibited-food.svg';
import { sendAmplitudeData } from '../../services/amplitude';
import {
  amplitude,
  porterDeliveryStep,
  porterDeliveryStepNames,
} from '../../constants';
import * as S from './styles';

const StepOpenDrawer = () => {
  const { toast } = useSwal();
  const {
    handleNextStep,
    handleBackStep,
    unity,
    groupUnity,
    groupUnities,
    uuidCondominium,
    phoneNumber,
    setUuidDelivery,
  } = useContext(context);

  const [t] = useTranslation('porter-delivery');

  const handleApolloError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [openBoxDrawer, { loading: loadingOpening }] = useMutation(
    mutations.openBoxDrawer,
    {
      variables: {
        uuid: uuidCondominium,
        phone: phoneNumber.raw,
        unityId: unity?.id ? Number(unity.id) : null,
      },
      onCompleted: response => {
        setUuidDelivery(response?.openBoxDrawer);
        handleNextStep();
        sendAmplitudeData(amplitude.porterDelivery.open);
      },
      onError: err => {
        handleApolloError(err);
        sendAmplitudeData(amplitude.porterDelivery.open_failure);
      },
    },
  );

  const confirmDeliveryTo = () => {
    if (!groupUnity) return t('open-drawer.confirm-to-condominium');

    return groupUnities.length > 1
      ? t('open-drawer.confirm-unity-group', {
          groupUnityName: groupUnity.name,
          unityName: unity.name,
        })
      : t('open-drawer.confirm-unity', {
          unityName: unity.name,
        });
  };

  useEffect(
    () =>
      sendAmplitudeData(amplitude.porterDelivery.page_view, {
        page: porterDeliveryStep[porterDeliveryStepNames.openDrawer],
      }),
    [],
  );

  return (
    <Layout onStepBack={handleBackStep}>
      <S.WrapperStepOpenDrawer>
        <S.Img src={fittingPiece} alt="Delivery Address" />
        <S.OpenDrawerTitle>
          {t('open-drawer.done')}
          <strong>{confirmDeliveryTo()}</strong>
        </S.OpenDrawerTitle>
        <S.OpenDrawerButton disabled={loadingOpening} onClick={openBoxDrawer}>
          {t('open-drawer.title')}
          {loadingOpening ? <Spinner size={14} /> : <MdOutlineArrowForward />}
        </S.OpenDrawerButton>
        <S.TipsWrapper>
          <S.TitleTips>{t('open-drawer.precautions')}</S.TitleTips>
          <S.TipItemsWrapper>
            <S.TipItem>
              <S.TipIcon src={prohibitedFragileObject} alt="Fragile Object" />
              <S.TipDescriptionWrapper>
                <S.TipTitle>{t('open-drawer.sensitive')}</S.TipTitle>
                <S.TipDescription>
                  {t('open-drawer.prohibited')}
                </S.TipDescription>
              </S.TipDescriptionWrapper>
            </S.TipItem>
            <Divider />
            <S.TipItem>
              <S.TipIcon src={prohibitedFood} alt="Prohibited Food" />
              <S.TipDescriptionWrapper>
                <S.TipTitle>
                  {t('open-drawer.prohibited-food-title')}
                </S.TipTitle>
                <S.TipDescription>
                  {t('open-drawer.prohibited-food-description')}
                </S.TipDescription>
              </S.TipDescriptionWrapper>
            </S.TipItem>
          </S.TipItemsWrapper>
        </S.TipsWrapper>
      </S.WrapperStepOpenDrawer>
    </Layout>
  );
};

export default StepOpenDrawer;
